
import { GameList } from "../src/list/GameList";
import Script from "next/script";
import { PageList } from "../src/list/PageList";
import { Assets } from "../src/list/Assets";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import dynamic from 'next/dynamic';
// import HorizontalScroll  from '/src/components/HorizontalScroll.js'
import { Suspense } from 'react'
import SkeletonLoader from '../src/components/SkeletonLoader';


const Headcom = dynamic(() => import('../src/components/Headcom'), {  });
const CatBtn = dynamic(() => import('../src/components/CatBtn'), {  });
const HomeDis = dynamic(() => import('../src/Description/HomeDis'), {  });
const FooterDis = dynamic(() => import('../src/Description/FooterDis'), {  });
const Pagination = dynamic(() => import('../src/components/Pagination'), {  });
const CookieCom = dynamic(() => import('../src/components/CookieCom'), {  });
const Searchcom = dynamic(() => import('../src/components/Searchcom'), {  });
const BannerCom = dynamic(() => import('../src/components/BannerCom'), {  });
const FooterCom = dynamic(() => import('../src/components/FooterCom'), {  });
const Ad_300x250_1 = dynamic(() => import('../src/ads/Ad_300x250_1'), {  });
const Ad_300x250_2 = dynamic(() => import('../src/ads/Ad_300x250_2'), {  });
const Ad_320x50 = dynamic(() => import('../src/ads/Ad_320x50'), {  });
const BlackScreen = dynamic(() => import('../src/components/BlackScreen'), {  });
const Snow_fx = dynamic(() => import('../src/fx/Snow_fx'), {  });
const HorizontalScroll = dynamic(() => import('../src/components/HorizontalScroll'), {
  loading: () => <SkeletonLoader/>,
});


export default function HomePage() {

  const path = useRouter();
  const [mobile, setMobile] = useState(false);

  
  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setMobile(isMobile);
  }, []);
  

// *************************************************************

const first50Games = GameList.slice(0, 50);


const getGamesByTag = (tag) => {
  try {
    if (!GameList || !Array.isArray(GameList)) {
      console.error('GameList is undefined or not an array');
      return [];
    }

    const filteredGames = GameList.filter(game => game.tag.includes(tag));

    // while (filteredGames.length < 10) {
    //   filteredGames.push(...filteredGames.slice(0, 10 - filteredGames.length));
    // }

    return filteredGames.slice(0, 30);
    // return filteredGames;
  } catch (error) {
    console.error('Error filtering games:', error);
    return [];
  }
};


  // Define URLs for each category
  const viewMoreUrls = {
    'Cooking': '/categories/cooking-games',
    'Exclusive': '/categories/exclusive-games',
    'New Games': '/categories/new-games',
    'LOL Surprise':'/categories/lol-surprise-games',
    'Christmas':'/categories/christmas-games',
    'Halloween':'/categories/halloween-games',
    'Doctor':'/categories/doctor-games',
    'Dino Games':'/categories/dino-games',
    'Snake Games':'/categories/snake-games'
  };

  return (
    <>
      <div className="app_cont_game">
        <Headcom
          title={PageList[0].title}
          s_dis={PageList[0].s_dis}
          path={PageList[0].path[1] + PageList[0].path[0]}
          thumb={PageList[0].thumb}
          logo_16={Assets[0].logo_16}
          // logo_32={Assets[0].logo_32}
          logo_192={Assets[0].logo_192}
          logo_270={Assets[0].logo_270}
          logo_512={Assets[0].logo_512}
        />
        <div>
        
          <BlackScreen />
         
          <div className="game-root">
            <div className="container">
                <HorizontalScroll games={getGamesByTag('Exclusive')} category="Recommended Games" viewMoreUrl={viewMoreUrls['Exclusive']} layoutType={"double"} showhot={'yes'}/>
                <HorizontalScroll games={first50Games} category="New Games" viewMoreUrl={viewMoreUrls['New Games']} layoutType={"single"}  />
                <HorizontalScroll games={getGamesByTag('Cooking')} category="Cooking Games" viewMoreUrl={viewMoreUrls['Cooking']} layoutType={"single"} />
                {mobile && <Ad_320x50 />} 
                <HorizontalScroll games={getGamesByTag('Dino')} category="Dino Games" viewMoreUrl={viewMoreUrls['Dino Games']} layoutType={"single"} />
                <HorizontalScroll games={getGamesByTag('LOL Surprise')} category="LOL Surprise Games" viewMoreUrl={viewMoreUrls['LOL Surprise']} layoutType={"single"} />
                <HorizontalScroll games={getGamesByTag('Christmas')} category="Christmas Games" viewMoreUrl={viewMoreUrls['Christmas']} layoutType={"single"}/>
                {mobile && <Ad_320x50 />} 
                <HorizontalScroll games={getGamesByTag('Halloween')} category="Halloween Games" viewMoreUrl={viewMoreUrls['Halloween']} layoutType={"single"}/>
                <HorizontalScroll games={getGamesByTag('Doctor')} category="Doctor Games" viewMoreUrl={viewMoreUrls['Doctor']} layoutType={"single"}/>
                <HorizontalScroll games={getGamesByTag('Snake')} category="Snake Games" viewMoreUrl={viewMoreUrls['Snake Games']} layoutType={"single"} />
                {mobile && <Ad_320x50 />} 
          <CatBtn />
          <HomeDis />
          <FooterDis />
          <CookieCom />
            </div>
          </div>
          
        </div>
      </div>
      <FooterCom />
      <Snow_fx />
    </>
  );
}